<template>
    <SectionLayout>
        <v-col cols="12" class="ma-0 pa-0">
        <v-row justify="center"  class="py-5">
            <v-col style="text-align: center">
                 <h1 class="display-2 font-weight-light">Sorry :(</h1>
            </v-col>
        </v-row>
        <v-row justify="center"  class="py-5 mt-5">
            <v-col style="text-align: center">
                 <p class="headline">We could not process the request.</p>
                 <p class="headline">
                    <span v-if="invalidRequest">The request was invalid.</span>
                    <span v-if="unknownError">We don't have further information.</span>
                    <span v-if="checkoutFailed">We don't have further information.</span>
                 </p>
            </v-col>
        </v-row>
        <v-row justify="center"  class="py-5">
            <v-col style="text-align: center">
                 <p class="headline">Please <a href="https://cryptium.com/contact/">contact us</a> for assistance<br/>
                 or continue to our <a href="https://customer.cryptium.com/">customer service</a> site</p>
            </v-col>
        </v-row>
        <v-row justify="center"  class="py-5" v-if="incidentId">
            <!-- border="left" colored-border  -->
            <v-alert dense outlined text type="info" elevation="2" class="text-center">
            Please share the following information with customer support
            <br/>
            <strong>Incident ID: {{incidentId}}</strong>
            </v-alert>
        </v-row>
        </v-col>
    </SectionLayout>
</template>

<style scoped>
p {
    color: #777;
}
p a {
    color: #0080ff;
}
</style>

<script>
import SectionLayout from '@/components/SectionLayout.vue';

export default {
    components: {
        SectionLayout,
    },
    data() {
        return {
            incidentId: null,
            invalidRequest: false,
            unknownError: false,
            checkoutFailed: false,
        };
    },
    mounted() {
        // window.location = 'https://cryptium.com';
        const { type } = this.$route.query;
        if (typeof type === 'string') {
            switch (type) {
            case 'invalid-request':
                this.invalidRequest = true;
                break;
            case 'checkout-failed':
                this.checkoutFailed = true;
                break;
            default:
                this.unknownError = true;
            }
        }
    },
};
</script>
